

export default {
  data() {
    return {
      structuredData: {
        "@context": "https://schema.org/",
        "@type": "Organization",
        "name": "Webconsol",
        "description": "Vă oferim servicii magento de dezvoltare si optimizare magazine online. Optimizarea SEO magazin online. Optimizarea pentru google speed page  | Webconsol",
        "logo": "https://webconsol.ro/_nuxt/assets/logo.svg",
        "image": "https://webconsol.ro/_nuxt/assets/logo.svg",
        "url": "https://webconsol.ro/",
        "telephone": "+40770512043"
      }
    }
  },
  methods: {
    scrollToElement() {
      const el = document.getElementById('features');
      el.scrollIntoView({behavior: "smooth"});
    }
  },
  
  
  head() {
    return {
        title: "Optimizare magazine online | SEO magazin online | Webconsol",
        meta: [
            {
              property: 'og:title',
              content: 'Optimizare magazine online | SEO magazin online | Webconsol',
            },
            {
                hid: "description",
                name: "description",
                content: "Vă oferim servicii magento de dezvoltare si optimizare magazine online. Optimizarea SEO magazin online. Optimizarea pentru google speed page  | Webconsol"
            },
            { 
              hid: 'keywords',
              name: 'keywords',
              content: 'optimizare magazine online,optimizare seo magazin online,servicii magento,seo magazin online,magazin online magento,platforme magazin online,magazin magento,Webconsol'
            },
            {
              property: 'og:description',
              content: ' - Vă oferim servicii magento de dezvoltare si optimizare magazine online. Optimizarea SEO magazin online. Optimizarea pentru google speed page  | Webconsol',
            },
            {
              property: 'og:keywords',
              content: 'optimizare magazine online,optimizare seo magazin online,servicii magento,seo magazin online,magazin online magento,platforme magazin online,magazin magento,Webconsol',
            },
        ],
        script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
}
}
